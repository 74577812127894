import * as ReactRedux from 'react-redux'
import * as ReactRouter from 'react-router-dom'
import * as FramerMotion from 'framer-motion'
import * as React from 'react'
import PropTypes from 'prop-types'

import * as t from '@rushplay/theme'
import * as Common from '@rushplay/common'
import styled from '@emotion/styled'

import * as CombinedSelectors from '../combined-selectors'
import * as Icons from '../icons'
import * as Inventory from '../inventory'
import { baseStyles } from '../menu-item/menu-item-paradiseplay'
import { useSafeUpdateQuery } from '../use-safe-update-query'

const DisabledLink = styled.span`
  ${baseStyles};
  color: ${t.color('g-text', 0.3)};
  opacity: 0.35;
  cursor: not-allowed;

  &:hover,
  &:active {
    background-color: initial;
    box-shadow: initial;
  }
`

const MenuLink = styled(ReactRouter.Link, {
  shouldForwardProp: Common.noneOf(['highlighted']),
})`
  ${baseStyles};
  color: ${props =>
    props.highlighted ? t.color('nav-highlight') : t.color('nav-item')};
  transition: color 150ms ease-in-out;

  &:active {
    box-shadow: inset 4px 0 0 0 ${t.color('nav-highlight')};
  }
`

const variants = {
  active: {
    rotate: [0, 20, -20, 20, -20, 0],
    transition: {
      duration: 1,
      loop: Infinity,
      repeatDelay: 6,
    },
  },
  inactive: {
    rotate: 0,
  },
}

export function PromotionsMenuItem(props) {
  const shouldAnimate = ReactRedux.useSelector(
    CombinedSelectors.hasPromotionNotifications
  )
  const hasUnseenInventory = ReactRedux.useSelector(state =>
    Inventory.hasUnseenItems(state.inventory)
  )
  const promotionsQuery = useSafeUpdateQuery({
    promotions: hasUnseenInventory ? 'inventory' : 'campaigns',
  })

  if (props.disabled) {
    return (
      <DisabledLink data-testid={props.testId}>
        <Icons.Notifications />
        <Common.Box
          fontSize={3}
          paddingLeft={1}
          display="inline-flex"
          color="g-text"
          fontFamily="head"
        >
          {props.children}
        </Common.Box>
      </DisabledLink>
    )
  }

  return (
    <MenuLink
      data-testid={props.testId}
      to={`?${promotionsQuery}`}
      onClick={props.onClick}
      highlighted={shouldAnimate}
    >
      <FramerMotion.motion.div
        variants={variants}
        animate={shouldAnimate ? 'active' : 'inactive'}
      >
        <Icons.Notifications />
      </FramerMotion.motion.div>
      <Common.Box
        fontSize={3}
        paddingLeft={1}
        display="inline-flex"
        color="g-text"
        fontFamily="head"
      >
        {props.children}
      </Common.Box>
    </MenuLink>
  )
}

PromotionsMenuItem.propTypes = {
  children: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  testId: PropTypes.string,
  onClick: PropTypes.func,
}

export default PromotionsMenuItem
